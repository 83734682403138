
.serviceRequest-container {
    width: 75%;
    margin: auto;
}

.service-header {
    font-size: 36px;
    font-weight: 500;
}

.service-para {
    margin-top: 25px;
    font-size: 18px;
    width: 90%;
    font-weight: 500;
}

.service-H_details {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 500;
}

.service-H_details a {
   color: #007bff;
   text-decoration: none;
}

.service-H_details a:hover {
   color: #1766bb;
   text-decoration: underline;
}

.service-form {
    margin-left: 5%;
    width: 85%;
    margin-top: 40px;
}

.input-div {
    display: grid;
    grid-template-columns: 15% 80%;
    column-gap: 19px;
    padding: 15px 0px;
}

.service-form-label {
    text-align: right;
    font-size: 18px !important;
}
.service-form-label2 {
    font-size: 18px !important;
    padding-bottom: 10px;
}
.service-form-label3 {
    font-size: 28px !important;
    font-weight: 500;
}
.service-form-input {
    width: 100%;
}

.checkbox-div {
    display: flex;
    margin-top: 10px;
}

.PleaseDescribe {
    background-color: #d1ecf1;
    border: 1px solid #bee5eb;
    padding: 10px 15px;
    border-radius: 5px;
    color: #0c5460;
    font-size: 18px;
}

.service-form-para2-H {
    font-size: 28px;
    font-weight: 500;
    margin: 15px 0px;
}

.service-form-checkbox {
    margin: 0px 15px 0px 0px;
}

.service-form-para2-text {
    font-size: 20px;
    margin: 15px 0px;
}

.Upload-Image {
    width: 300px;
    height: 150px;
    display: block;
    border: 1px dashed black;
}

.custom-file-input {
    visibility: hidden;
    width: 0;
    font-size: 0px;
    color: rgba(255, 255, 255, 0);
}

.custom-file-label {
    border: 2px dashed rgb(189, 189, 189);
    background-color: #f8f9fa;
    color: #007BFF;
    border-radius: 5px;
    width: 100%;
    padding: 10% 0px;
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
}

.custom-file-label::after {
    content: '';
}

.custom-file-input:focus + .custom-file-label {
    border-color: #0056b3;
}

.custom-file-input::before {
    content: '';
    display: block;
    background-color: transparent;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
}

.custom-file-input:hover::before {
    background-color: #0056b3;
}

.Property-Currently-button {
    padding: 6px 40px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: #fff;
}

.Property-Currently-button:hover {
    color: #fff;
    background-color: #0361c5;
    transition: 0.9s;
}

/*  media Queries  */

@media screen and (max-width: 800px) {
    .serviceRequest-container {
        width: 90%;
    }
    .service-para {
        width: 100%;
    }
    .service-form {
        width: 100%;
        margin-left: 0px;
    }
    .input-div {
        grid-template-columns: 20% 75%;
        column-gap: 15px;
    }
    .service-form-para2-text {
        font-size: 16px;
    }
    .service-form-label3 {
        font-size: 24px;
    }
}

@media screen and (max-width: 550px) {
    .service-header {
        font-size: 26px;
    }

    .service-form-para2-H{
        font-size: 22px;
    }
    .service-form {
        width: 100%;
        margin-left: 0px;
    }
    .input-div {
        grid-template-columns: auto;
        column-gap: 0px;
    }
    .service-form-label {
        text-align: left;
    }
    .service-para {
        font-size: 16px;
    }
    .service-H_details {
        font-size: 16px;
    }
    .service-form-label {
        font-size: 16px;
        padding-bottom: 10px;
    }
    .service-form-label2 {
        font-size: 16px;
    }
    .service-form-label3 {
        font-size: 20px;
    }
    .PleaseDescribe {
        font-size: 16px;
    }
    
}