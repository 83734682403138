/* .button-c {
    min-width: 200px
}

.image-container {
    min-width: 100%;
    max-width: 100%;
    min-height: 300px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000030;
} */


.button-c {
    min-width: 200px
}

.image-container {
    min-width: 100%;
    max-width: 100%;
    min-height: 300px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000030;
}

.image-containers {
    position: relative;
    min-width: 100%;
    max-width: 100%;
    min-height: 300px;
    max-height: 500px;
    object-fit: cover;
    box-shadow: 0 0 10px #00000030;
}

.tedxtblue {
    color: blue;
}
.seePhotoBtn{
    font-size: 18px;
    border: none;
    width: 200px;
    padding: 4px 0px;
    color: rgb(0, 110, 255);
    background: #eee;
    border-radius: 15px;
    position: absolute;
    z-index: 100;
    right: 20px;
    top: 280px;

}
.closeBtn{
    position: absolute;
    z-index: 10;
    border: none;
    top: 0;
    right: 0;
    font-size: 25px;
    background: transparent;
}

.listing .features-panel {
    margin-top: 100px;
}

.panel {
    position: relative;
    padding: 50px;
    border-radius: 8px;
    background-color: #f5f5f5;
}

.panel .panel-title {
    position: absolute;
    font-size: 36px;
    top: -30px;
    left: 20px;
    color: #383838;
}