/* custom.scss */
$primary: #044dac;
// $secondary: #8f5325;
// $success: #3e8d63;
// $info: #7854e4;
// $warning: #b8c924;
// $danger: #d62518;
// $light: #f8f9fa;
// $dark: #343a40;
/* end custom.scss */

@import '~bootstrap/scss/bootstrap.scss';