
.SubletAgreementMainDiv{
    width: 100vw;
}

.SubletAgreementMain{
    width: 90%;
    margin: auto;
}

.SubletAgreementPara1Input{
    border: none;
    outline: none ;
    border-bottom: 1px solid;
    
}

.SubletAgreementPara2Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 100%;
}

.SubletAgreementPara3Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 80%;
}

.SubletAgreementPara4Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
}

.SubletAgreementPara5Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 50%;
}
.SubletAgreementPara6Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 50%;
}

.SubletAgreementPara7Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 50%;
}

.SubletAgreementPara8Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
}

.SubletAgreementPara10Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
}

.SubletAgreementPara11{

    display: flex;
    width: 80%;
}
.SubletAgreementPara11Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 100%;
}

.SubletAgreementPara12Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
}

.SubletAgreementPara13Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
}

.SubletAgreementPara14Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
}

.SubletAgreementPara15Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 30%;
}
.SubletAgreementPara16Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 30%;
}
.SubletAgreementPara17Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 30%;
}
.SubletAgreementPara18Input{
    border: none;
    outline: none;
    border-bottom: 1px solid;
    width: 30%;
}