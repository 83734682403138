
.MorePhotos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.MorePhotos-Img {
    cursor: pointer;
    display: block;
    width: 100px;
    height: 100px;
    margin: 10px 10px;
}

.feature-list {
    padding-left: 0px;
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.Features-li {
    list-style-type: none;
    margin: 10px 0px;
}

@media screen and (max-width: 800px) {
    .feature-list {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 550px) {
    .feature-list {
        grid-template-columns: auto;
    }
}