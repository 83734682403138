.hero-container {
    background-color: #efefef;
}

.hero-item {
    padding: 70px;
}

.blue {
    color: #044dac;
}