.properties-container {
    max-height: 70vh;
    overflow-y: auto;
}

.advance-main-div {
    display: grid;
    padding: 0px 10px;
    grid-template-columns: auto auto;
    column-gap: 1%;
    width: 100%;
}

.properties-map-select {
    width: 100%;
}

.properties-map-check {
    display: flex;
    flex-wrap: wrap;
    margin-right: 15px;
}

.properties-map-check input[type="checkbox"] {
    width: 18px;
    margin-right: 10px;
}

.properties-map-check div {
    font-size: 18px;
}

.properties-content2 {
    /* display: grid;
    grid-template-columns: 31% 31% 31%;
    column-gap: 3%; */
    padding: 10px 10px;
    margin-top: 15px;
}

#advance-collapse {
    display: none;
}

#collapseHide {
    display: none;
}



/* listings css */



.browse-grid2 {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    column-count: 4; */
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.browse-grid2 label.ms-2 {
    display: flex;
}

.browse-grid2 label.ms-2 label {
    margin-top: 12px !important;
}



@media screen and (max-width: 800px) {
    .properties-map-check input[type="checkbox"] {
        width: 14px;
    }
    .browse-grid2 {
        grid-template-columns: auto auto;
    }
}


/* listing css media */

@media screen and (max-width: 550px) {
    .browse-grid2 {
        grid-template-columns: auto auto;
    }
    .advance-main-div {
        grid-template-columns: 49% 49%;
        justify-content: space-between;
    }
}

.Pdetails{
    border-bottom: 1px solid #eee;
}

.Pdetails span{
    color: #dab2b2;
}