.main-container {
    background-color: #efefef;
}

.login-container {
    min-height: 100vh;
}

.blue {
    color: #044dac;
}

.back {
    position: absolute;
    top: 15px;
    left: 15px;
}