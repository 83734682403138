#applicant-wrapper label{
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 10px;
}

.rentalHPlocation {
    width: 15px;
    height: 15px;
}

.custom-file-input {
    visibility: hidden;
    width: 0;
    font-size: 0px;
    color: rgba(255, 255, 255, 0);
}

.custom-file-label {
    border: none !important;
    background-color: #dbdbdb !important;
    color: #2c2c2c !important;
    border-radius: 5px;
    width: 100%;
    padding: 15px 0px !important;
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
}

.custom-file-label::after {
    content: '';
}

.custom-file-input:focus + .custom-file-label {
    border-color: #0056b3;
}

.custom-file-input::before {
    content: '';
    display: block;
    background-color: transparent;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
}

.custom-file-input:hover::before {
    background-color: #0056b3;
}

.collapse-form2 {
    margin-right: 0px !important;
}

.petsForm-Collapse {
    width: 100% !important;
    display: block !important;
    object-fit: cover;
}

.YesNoDropdown {
    position: relative;
}

.YesNoButton {
    position: absolute;
    top: -25px;
    left: 45px;
}