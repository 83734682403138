

.ScheduleShowing-header {
    font-size: 34px;
    font-weight: 500;
}

.ScheduleShowing-top-para {
    margin-top: 20px;
    font-size: 18px;
    letter-spacing: 0.4px;
    word-spacing: 1px;
    width: 80%;
    margin-bottom: 20px;
}

.ScheduleShowing-form {
    margin-top: 40px;
}

.ScheduleShowing-input {
    display: grid;
    grid-template-columns: 20% 75%;
    column-gap: 3%;
    width: 80%;
    margin-left: 5%;
    margin-top: 20px;
}

.ScheduleShowing-input label {
    text-align: right;
    font-size: 18px;
}
.ScheduleShowing-Pleaseprovide {
    width: 90%;
    font-size: 18px;
}

.rechaptcha-text {
    font-size: 18px;
}

@media screen and (max-width: 800px) {
    .ScheduleShowing-top-para {
        width: 100%;
        font-size: 16px;
    }
    .ScheduleShowing-header {
        font-size: 26px;
    }
    .ScheduleShowing-Pleaseprovide {
        width: 100%;
        font-size: 15px;
    }
    .ScheduleShowing-input {
        column-gap: 3%;
        grid-template-columns: 23% 74%;
        width: 90%;
        margin-left: 15px;
    }
    .ScheduleShowing-input label {
        font-size: 16px;
    }
    .rechaptcha-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 550px) {
    .ScheduleShowing-top-para {
        width: 100%;
    }
    .ScheduleShowing-input {
        grid-template-columns: auto;
    }
    .ScheduleShowing-input label {
        text-align: left;
        margin-bottom: 10px;
    } 
}