.Tenent-container {
    width: 90%;
    margin: auto;
}

.Tenent-row {
    height: auto;
    display: grid;
    grid-template-columns: 23%  23% 23% 23%;
    justify-content: space-between;
}

.Tenent-column {
    height: 100% !important;
}

.tenent-card {
    height: 100% !important;
    margin: auto;
}

.card-body-header {
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
}

.card-body-content {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 15px;
}

.Sublet-link {
    color: #007bff;
    text-decoration: none;
}

.Sublet-link:hover {
    text-decoration: underline;
    color: #116fd4;
}

.card-footer-button {
    background-color: #007bff !important;
    color: white !important;
    font-weight: 500 !important;
    display: block !important;
    width: 60% !important;

}


/*  Tenent media start  */


@media screen and (max-width: 800px) {
    .Tenent-row {
        grid-template-columns: 47% 47%;
    }
    .Tenent-column {
        padding-bottom: 25px;
    }
}
@media screen and (max-width: 550px) {
    .Tenent-row {
        grid-template-columns: auto;
    }
    .Tenent-column {
        padding-bottom: 25px;
    }
}

