body{
    margin: 0;
    padding: 0;
    background-color: #eee;
}
*{
    box-sizing: border-box;
}
.blue {
    color: #044dac;
}
