.view-button {
    width: 130px;
}

.price-container {
    margin-top: 60px;
}

.main-container {
    padding-bottom: 20px;
    border-bottom: 1px solid #aaa;
}

.image-container {
    min-width: 100%;
    max-width: 100%;
    max-height: 250px;
    min-height: 250px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000030;
}